@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;700&display=swap");
@font-face {
  font-family: "FS Elliot Pro";
  src: url("fonts/FSElliotPro-Regular.otf") format("opentype");
}

@font-face {
  font-family: "FS Elliot Pro";
  font-weight: 800;
  src: url("fonts/FSElliotPro-Heavy.woff") format("woff");
}
/* -------------------------------------------------------------------------- */
/*                                  bootstrap                                 */
/* -------------------------------------------------------------------------- */

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px,
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1040px,
  xxl: 1200px,
);

@import "~bootstrap/scss/bootstrap";

a {
  color: #22264c;
}
/* -------------------------------------------------------------------------- */
/*                                  Container                                 */
/* -------------------------------------------------------------------------- */
.container.md {
  @include media-breakpoint-up(sm) {
    max-width: 480px;
  }
  @include media-breakpoint-up(md) {
    max-width: 670px;
  }
  @include media-breakpoint-up(lg) {
    max-width: 760px;
  }
  @include media-breakpoint-up(xl) {
    max-width: 840px;
  }
  @include media-breakpoint-up(xxl) {
    max-width: 1080px;
  }
}

// todo fix nav bar when in 5s

// .container {
//   padding-left: 10% !important;
//   padding-right: 10% !important;
// }

////////////////////
////// NAVBAR //////
////////////////////

.navbar {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  padding-top: 0;
  padding-bottom: 36px;
}

.navbar-brand {
  margin-top: 36px;
}

.navBarImg {
  width: 118px;
  height: 32px;
}

.navbar-nav {
  margin-top: 36px;
  margin-left: 5%;
  text-align: center;
}

.navbar-nav .nav-link {
  color: #22264c !important;
}

.navbar-toggler {
  margin-top: 20px;
  font-size: 1rem !important;
  border: none !important;
  color: transparent !important;
}

@media only screen and (max-width: 600px) {
  .navbar-brand {
    margin-top: 16px;
  }
  .navLink {
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #22264c;
    margin-bottom: 32px;
  }
}

////////////////////
/////// HERO ///////
////////////////////

.heroWrapper {
  margin-top: 80px !important;
  margin-bottom: 100px;
  display: flex;
  // margin-left: 80px !important;
  // margin-right: 80px !important;
}

.heroWrapperCenter {
  align-items: center;
}

.heroWrapperMain {
  margin-top: 150px !important;
  margin-bottom: 150px;
  width: 60%;
}

.heroMainImg {
  position: absolute;
  top: 0;
  width: 45% !important;
  right: 0;
}

.heroTitleL {
  font-family: FS Elliot Pro;
  font-style: normal;
  font-weight: 800;
  font-size: 64px;
  line-height: 70px;
  color: #22264c;
}

.heroTitleM {
  font-family: FS Elliot Pro;
  font-style: normal;
  font-weight: 800;
  font-size: 52px;
  line-height: 70px;
  color: #22264c;
}

.heroSubtitle {
  font-family: Lato;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 150%;
  color: #22264c;
  margin-top: 32px;
  max-width: 400px;
}

.heroParagraph {
  font-size: large;
}
.heroInputWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 10px 10px 24px;

  position: static;
  width: 100%;
  height: 69px;
  left: 0px;
  top: 230px;

  background: #ffffff;
  border: 1px solid rgba(34, 38, 76, 0.12);
  box-sizing: border-box;
  box-shadow: 0px 121px 118px rgba(34, 38, 76, 0.06),
    0px 36.478px 35.5735px rgba(34, 38, 76, 0.0390953),
    0px 15.1511px 14.7754px rgba(34, 38, 76, 0.03),
    0px 5.47985px 5.34398px rgba(34, 38, 76, 0.0209047);
  border-radius: 8px;

  margin: 24px 0px;
}

.heroInput {
  border: none;
  width: 80%;
}

.heroInput:focus {
  outline: none;
  background: #ffffff;
}

.heroInputButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 16px 32px;

  position: static;
  width: 162px;
  height: 49px;
  right: 10px;
  bottom: 10px;

  background: #e55a5c;
  border-radius: 6px;
  border: none;

  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 0px;
}

.heroInputButtonText {
  position: static;
  height: 17px;
  left: 32px;
  right: 32px;
  top: calc(50% - 17px / 2);

  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;

  display: flex;
  align-items: center;
  text-align: center;

  color: #ffffff;

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px;
}

.heroHelperText {
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: #22264c !important;
  margin-top: auto;
  margin-bottom: auto;
}

.heroConsent {
  color: #22264c;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  font-family: Lato;
}

.heroButtons {
  display: flex;
  flex-direction: row;
}

.heroButtonsEU{
  display: flex;
  flex-direction: row;
  margin-top: 15px;
}

.heroImageEU{
  margin-top: 16px;
  margin-left: auto;
  margin-right: auto;
}

.imgElavateWebEN{
  width: 80%;
}

.appleStoreButton {
  display: flex;
  flex-direction: row;
  // align-items: center;
  padding: 14px 24px 14px 20px;
  // width: 135px;
  height: 48px;
  background: #f0f2f8;
  border-radius: 6px;
  border: 0;
  display: flex;
  margin-left: 16px;
}

.playStoreButton {
  display: flex;
  flex-direction: row;
  // align-items: center;
  padding: 14px 24px 14px 20px;
  // width: 135px;
  height: 48px;
  background: #f0f2f8;
  border-radius: 6px;
  border: 0;
  display: flex;
  margin-left: 8px;
}

.appleStoreButtonText {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  // line-height: 17px;
  // text-align: right;
  color: #22264c;
  margin-left: 8px;
}

.googlePlayStoreButtonText {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  // line-height: 17px;
  // text-align: right;
  color: #22264c;
  margin-left: 8px;
}

.heroImageText {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #22264c;
  opacity: 0.5;
  margin-bottom: 30px;
}

.heroImageTextLeft {
  text-align: left;
  white-space: nowrap;
}

.heroImageTextRight {
  text-align: right;
  margin-left: 500px;
  white-space: nowrap;
}

.heroImageLeft {
  margin-left: auto;
  display: block;
  width: 100%;
}

.heroImageRight {
  margin-right: auto;
  display: block;
  width: 100%;
}

.heroText {
  order: 1;
}
.heroImage {
  order: 2;
}

@media only screen and (max-width: 1320px) {
  .heroText {
    order: 2 !important;
  }
  .heroMainImg {
    width: 40%;
  }
}

@media only screen and (max-width: 1200px) {
  .heroInputButton {
    display: block;
    width: 120px;
  }
  .heroWrapperMain {
    width: auto;
  }
  .heroMainImg {
    visibility: hidden;
  }
  .heroButtons {
    flex-flow: wrap;
  }
  .heroButtonsEU {
    flex-flow: wrap;
  }
  .heroHelperText {
    text-align: center;
    flex-basis: 100%;
    margin-bottom: 8px;
  }
  .appleStoreButton {
    margin-left: auto;
  }
  .playStoreButton {
    margin-right: auto;
  }
}

@media only screen and (max-width: 767px) {
  .heroTitleL {
    width: 100%;
    font-size: 36px;
    line-height: 48px;
    text-align: center;
  }
  .heroTitleM {
    font-size: 24px;
    line-height: 130%;
    text-align: center;
  }
  .heroWrapper {
    text-align: center;
    margin-top: 50px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
    margin-bottom: 0px !important;
  }
  .heroImagewrapper {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }
  .heroImageText {
    display: none;
  }
  .heroImageRight,
  .heroImageLeft {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin-right: 0;
    margin-left: 0;
  }
}

@media only screen and (max-width: 370px) {
  .heroButtons {
  }
  .heroButtonsEU {
  }
  .playStoreButton {
    padding: 14px;
  }
  .appleStoreButton {
    padding: 14px;
  }
}

////////////////////
//// CARD DECK /////
////////////////////

.cardDeckWrapper {
  margin-top: 60px;

  @include media-breakpoint-only(xs) {
    .cardDeckParagraph {
      margin-right: 30px !important;
      margin-left: 30px !important;
    }
    .cardDeckTitle {
      margin-left: 30px !important;
    }
    .cardDeckNumber {
      margin-left: 30px !important;
    }
  }
  @include media-breakpoint-up(md) {
    margin-bottom: 100px;
  }

  .card {
    height: 500px !important;
    @include media-breakpoint-up(sm) {
      height: 480px !important;
    }
    @include media-breakpoint-up(md) {
      height: 400px !important;
    }
    @include media-breakpoint-up(lg) {
      height: 400px !important;
    }
    @include media-breakpoint-up(xl) {
      height: 500px !important;
    }
  }
}

.cardDeckTitle {
  font-family: FS Elliot Pro;
  font-style: normal;
  font-weight: 800;
  font-size: 100px;
  line-height: 120%;
  color: #22264c;
  margin-left: 100px;
  margin-top: 100px;
}

.cardDeckParagraph {
  font-family: Lato;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 150%;
  color: #22264c;
  margin-right: 100px;
  margin-top: 120px;
}

.cardDeckNumber {
  font-family: FS Elliot Pro;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  margin-top: 130px;
  color: #22264c;
  margin-left: 100px;
  margin-bottom: 53px;
}

@media only screen and (max-width: 1200px) {
  .cardDeckNumber {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 1000px) {
  .cardDeckTitle {
    font-size: 30px;
    margin-left: 70px;
    margin-top: 70px;
  }
  .cardDeckParagraph {
    margin-right: 70px;
    margin-top: 70px;
  }
  .cardDeckNumber {
    margin-top: 30px;
    margin-left: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .col {
    flex: none !important;
  }
  .cardDeckParagraph {
    margin-right: 70px;
    margin-top: 20px;
    margin-left: 70px;
  }
}

/////////////////////
//// ICON HEADER ////
/////////////////////

.iconHeaderTitleWrapper {
  width: 80%;
  margin-right: auto;
  margin-left: auto;
}

.iconHeaderTitle {
  font-family: FS Elliot Pro;
  font-style: normal;
  font-weight: 800;
  font-size: 64px;
  line-height: 70px;
  text-align: center;
  color: #22264c;
  margin-top: 60px;
}

.iconArrayWrapper {
  display: flex;
  justify-content: center;
  margin-top: 76px;
  margin-bottom: 60px;
  column-gap: 140px;
}

.iconArrayIndividualWrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 172px;
  height: 100%;
}

.iconArrayImg {
  height: 55px;
}

.iconArrayTitle {
  font-family: FS Elliot Pro;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 130%;
  text-align: center;
  color: #22264c;
  margin-top: 35px;
}

.iconArrayDescription {
  font-family: Lato;
  font-style: normal;
  font-weight: 300;
  font-size: medium;
  line-height: 150%;
  text-align: center;
  color: #22264c;
}

@media only screen and (max-width: 767px) {
  .iconHeaderTitleWrapper {
    width: 100%;
  }
  .iconHeaderTitle {
    font-size: 36px;
    line-height: 48px;
  }
  .iconArrayWrapper {
    column-gap: 10px;
  }
  .iconArrayIndividualWrapper {
    max-width: 100px;
  }
}

////////////////////
//// CARD FEAT /////
////////////////////

.cardFeaturedWrapper {
  text-align: center;
}

.cardFeaturedTitle {
  font-family: FS Elliot Pro;
  font-style: normal;
  font-weight: 800;
  font-size: 52px;
  line-height: 57px;
  align-items: center;
  text-align: center;
  color: #22264c;
  margin-top: 83px;
}

.cardGroupWrapper {
  margin-top: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cardGroup {
  column-gap: 88px;
  justify-content: space-between;
}

.cardFeatured {
  border: none !important;
}

.cardFeaturedImage {
  border-radius: 16px;
  width: 300px;
  // height: 316px;
}

.cardFeaturedCardTitle {
  font-family: Lato;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 130%;
  text-align: center;
  color: #22264c;
  margin-top: 28px;
}

.cardFeaturedCardSubtitle {
  font-family: Lato;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: #22264c;
  margin-top: 16px;
}

.cardFeaturedCardLinks {
  display: flex;
  justify-content: center;
  column-gap: 20px;
}

@media only screen and (max-width: 767px) {
  .cardFeaturedTitle {
    font-size: 24px;
    line-height: 130%;
  }
}

////////////////////
///// ARTICLE //////
////////////////////

.articleWrapper {
  margin-top: 89px;
}

.articleTitle {
  font-family: FS Elliot Pro;
  font-style: normal;
  font-weight: 800;
  font-size: 52px;
  line-height: 57px;
  text-align: center;
  color: #22264c;
}

@media only screen and (max-width: 767px) {
  .articleWrapper {
    margin-top: 85px;
  }
  .articleTitle {
    font-family: FS Elliot Pro;
    font-style: normal;
    font-weight: 800;
    font-size: 28px;
    line-height: 37px;
    text-align: center;
  }
}

.articleDescription {
  font-family: Lato;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 150%;
  margin-top: 40px;
  color: #22264c;
}

.articleTextWrapper {
  margin-top: 28px;
}

.articleSubtitle {
  font-family: FS Elliot Pro;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 130%;
  color: #22264c;
}

.articleItemsWrapper {
  margin-top: 20px;
}

.articleItemsTitleWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px 0px 0px 16px;
}

.articleItemsTitleNumber {
  font-family: FS Elliot Pro;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 130%;
  color: #22264c;
}

.articleItemsTitle {
  font-family: FS Elliot Pro;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 130%;
  color: #22264c;
  margin-left: 20px;
  margin-right: 20px;
}

.articleItemsDescriptionWrapper {
  margin-top: 20px;
  padding: 0px 0px 0px 36px;
}

.articleItemsDescription {
  font-family: Lato;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 150%;
  color: #22264c;
}

.articleItemsNumberedDescription {
  font-family: Lato;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 150%;
  color: #22264c;
  margin-left: 50px;
}

.articleItemsBulletPointWrapper {
  margin-left: 25px;
}

.articleItemsBulletPointTitle {
  font-family: FS Elliot Pro;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 130%;
  color: #22264c;
}

.articleItemsBulletPointDescription {
  font-family: Lato;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 150%;
  color: #22264c;
}

.articleItemsBulletPointNumberedDescription {
  font-family: Lato;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 150%;
  color: #22264c;
  margin-left: 50px;
}

.articleItemsGroupedNumberedDescription {
  font-family: Lato;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 150%;
  color: #22264c;
  margin-left: 50px;
}

.articleItemsGroupedDescription {
  font-family: Lato;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 150%;
  color: #22264c;
  margin-left: 100px;
}

////////////////////
///// MESSAGE //////
////////////////////

.messageWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  margin-top: 124px;
}

.messageTitle {
  font-family: FS Elliot Pro;
  font-style: normal;
  font-weight: 800;
  font-size: 80px;
  line-height: 88px;
  text-align: center;
  color: #e55a5c;
}

.messageDescription {
  font-family: FS Elliot Pro;
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 40px;
  text-align: center;
  color: #22264c;
  margin-top: 23px;
}

.messageButton {
  display: flex !important;
  justify-content: center;
  align-items: center;
  padding: 16px 32px;
  width: 170px;
  height: 49px;
  background: #e55a5c;
  border-radius: 6px;
  border: none;
  margin-top: 60px;
}

.messageButtonText {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
}

@media only screen and (max-width: 767px) {
  .messageTitle {
    font-size: 64px;
  }
  .messageDescription {
    font-size: 24px;
  }
  .messageButton {
    margin-top: 56px;
  }
}

////////////////////
// CONTACT CARD ////
////////////////////

.contactCardWrapper {
  margin-top: 40px;
}

.contactCard {
  width: 100%;
  height: 100%;
  min-height: 499px;
  background: #22264c !important;
  border-radius: 32px !important;
  border: none !important;

  &.submitted {
    text-align: center;
    min-height: unset;
    background: #217753 !important;
    padding-bottom: 40px;
  }
}

.contactCardTextWrapper {
  margin-top: 60px;
  margin-left: 80px;
}

.contactCardTitle {
  font-family: FS Elliot Pro;
  font-style: normal;
  font-weight: 800;
  font-size: 52px;
  line-height: 57px;
  color: #ffffff;
}

.contactCardSubtitle {
  font-family: Lato;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 150%;
  color: #ffffff;
  margin-top: 32px;
}

.contactCardFormWrapper {
  margin-top: 60px;
  margin-right: 80px;
}

.contactCardFormTitle {
  font-family: FS Elliot Pro;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 130%;
  text-align: center;
  color: #ffffff;
}

.contactCardFormInput {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 12px 16px;
  width: 100%;
  height: 45px;
  background: rgba(255, 255, 255, 0.16);
  border-radius: 6px;
  border: none;
  font-family: Lato;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 150%;
  color: #ffffff;
  margin-top: 12px;
}

.contactCardFormSelected {
  color: black;
}

.contactCardFormCheck {
  margin-top: 12px;
}
.subscribeWrapper {
  margin-bottom: 30px !important;
}

.form-check {
  display: flex;
  flex-wrap: nowrap;

}
.contactCardFormCheckbox.form-check-input[type="checkbox"] {
  border-radius: 0.25em;
  border: 1px solid #696c8d;
  background-color: transparent;
  outline: none;
}

.contactCardText {
  font-family: Lato;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 150%;
  color: #ffffff;
}

.contactCardFormInputTextArea {
  height: 108px;
  min-height: 108px;
  max-height: 108px;
}

.contactCardFormInput::placeholder {
  font-family: Lato;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 150%;
  color: #ffffff;
}

.contactCardFormButton {
  width: 100%;
  height: 49px;
  background: #e55a5c;
  border-radius: 6px;
  border: none;
  margin-bottom: 40px;
}

.contactCardFormButtonText {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
  margin: 0px 10px;
}

@media only screen and (max-width: 767px) {
  .contactCardTextWrapper {
    margin-left: 24px;
    margin-right: 24px;
  }
  .contactCardTitle {
    font-size: 24px;
    text-align: center;
    line-height: 130%;
  }
  .contactCardSubtitle {
    text-align: center;
    margin-top: 12px;
  }
  .contactCardFormWrapper {
    margin-left: 24px;
    margin-right: 24px;
    margin-top: 32px;
  }
  .contactCardFormTitle {
    font-size: 16px;
  }
}

////////////////////
//// QUESTIONS /////
////////////////////

.questionWrapper {
  margin-top: 120px;
}

.questionTitle {
  font-family: FS Elliot Pro;
  font-style: normal;
  font-weight: 800;
  font-size: 52px;
  line-height: 57px;
  text-align: center;
  color: #22264c;
}

.questionAnswersWrapper {
  margin-top: 62px;
}

.questionColumnWrapper {
  margin-top: 40px !important;
}

.question {
  font-family: FS Elliot Pro;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 130%;
  color: #22264c;
}

.answer {
  font-family: Lato;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 150%;
  color: #22264c;
}

@media only screen and (max-width: 767px) {
  .questionWrapper {
    margin-top: 80px;
  }
  .questionTitle {
    font-size: 24px;
  }
  .questionAnswersWrapper {
    margin-top: 20px;
  }
  .questionColumnWrapper {
    margin-top: 10px !important;
  }
}

/////////////////////////////
///// APPLICATION CARD //////
/////////////////////////////

.applicationCardWrapper {
  margin-top: 80px;
  margin-bottom: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.applicationCard {
  width: 100%;
  max-width: 1172px;
  height: 100%;
  min-height: 563px;
  background-image: url("images/bikes.png") !important;
  border-radius: 32px !important;
  border: none !important;
}

.applicationCardTextWrapper {
  margin-top: 60px;
  margin-left: 100px;
}

.applicationCardLogo {
  width: 103px;
  height: 27px;
}

.applicationCardTitle {
  font-family: FS Elliot Pro;
  font-style: normal;
  font-weight: 800;
  font-size: 52px;
  line-height: 57px;
  color: #22264c;
  margin-top: 36px;
  max-width: 572px;
}

.applicationCardFormWrapper {
  margin-top: 40px;
  margin-left: 100px;
  display: flex;
  flex-direction: column;
}

.applicationCardFormInput {
  align-items: center;
  padding: 10px 10px 10px 24px;
  width: 100%;
  height: 69px;
  background: #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 121px 118px rgba(34, 38, 76, 0.06),
    0px 36.478px 35.5735px rgba(34, 38, 76, 0.0390953),
    0px 15.1511px 14.7754px rgba(34, 38, 76, 0.03),
    0px 5.47985px 5.34398px rgba(34, 38, 76, 0.0209047);
  border-radius: 8px;
  border: none;
  order: 1;
}

.applicationCardFormInput:focus {
  outline: none;
  background: #ffffff;
}

.applicationCardFormButton {
  width: 100%;
  height: 49px;
  background: #e55a5c;
  border-radius: 6px;
  border: none;
  order: 3;
}

.applicationCardFormButtonText {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
  margin: 0px 10px;
}

.applicationCardFormCheck {
  margin-top: 16px;
  order: 2;
}

.applicationCardFormCheckbox.form-check-input[type="checkbox"] {
  border-radius: 0.25em;
  border: 1px solid #22264c;
  background-color: transparent;
  outline: none;
}

.applicationCardText {
  font-family: Lato;
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 150%;
  color: #22264c;
}

@media only screen and (max-width: 767px) {
  .applicationCardTextWrapper {
    margin-left: 28px;
    margin-right: 28px;
  }
  .applicationCardLogo {
    display: block;
    margin: auto;
  }
  .applicationCardTitle {
    font-size: 24px;
    text-align: center;
    line-height: 130%;
  }
  .applicationCardFormWrapper {
    margin-left: 28px;
    margin-right: 28px;
    margin-top: 24px;
    // display: flex;
    // flex-direction: column;
  }
  // .applicationCardFormInput {
  //   order: 1;
  // }
  // .applicationCardFormButton {
  //   order: 3;
  // }
  // .applicationCardFormCheck {
  //   order: 2;
  // }
}

////////////////////
///// FOOOTER //////
////////////////////

.footer {
  background-color: #22264c;
  border-radius: 48px 48px 0px 0px;
  margin-top: 80px;
}

.footerRow {
  margin-left: 100px !important;
  margin-right: 100px !important;
}

.footerParagraphCol {
  flex: 0 0 auto !important;
  width: 33.33333333% !important;
}

.footerImg {
  margin-top: 80px;
  height: 32px;
}

.footerParagraph {
  font-family: Lato;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 150%;
  color: #ffffff;
  margin-top: 32px;
}

.menuCol {
  order: 1;
}

.follow1Col {
  order: 2;
}

.follow2Col {
  order: 3;
}

.contactCol {
  order: 4;
}

.footerTitle {
  font-family: Lato;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 150%;
  color: #ffffff;
  margin-top: 92px;
}

.footerTitleNone {
  color: transparent;
}

.footerLink {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  margin-top: 20px;
  text-decoration: none;
}

.footerLinkImage {
  margin-right: 10px;
}

.footerCopyrightRow {
  flex: 0 0 auto !important;
  width: 66.66666666% !important;
  order: 1;
  margin-top: 60px;
  margin-bottom: 42px;
}

.footerCopyright {
  font-family: Lato;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 150%;
  color: #ffffff;
}

.footerPrivacyPoliciesRow {
  order: 2;
  margin-top: 60px;
  margin-bottom: 42px;
}

.footerPrivacyPolicies {
  font-family: Lato;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 150%;
  color: #ffffff;
  text-decoration: none;
}

.footerTermsAndConditionsRow {
  order: 3;
  margin-top: 60px;
  margin-bottom: 42px;
}

.footerTermsAndConditions {
  font-family: Lato;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 150%;
  color: #ffffff;
  text-decoration: none;
}

@media only screen and (max-width: 1000px) {
  .footerLinkImage {
    margin-right: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .footerRow {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
  .footerParagraphCol {
    flex: 0 0 auto !important;
    width: 100% !important;
    text-align: center;
  }
  .footerTitle {
    margin-top: 40px;
  }
  .footerLinkImage {
    margin-right: 10px;
  }
  .menuCol {
    order: 1;
    width: 45% !important;
  }
  .follow1Col {
    order: 3;
    width: 45% !important;
  }
  .follow2Col {
    order: 4;
    width: 45% !important;
  }
  .contactCol {
    order: 2;
    width: 45% !important;
  }
  .footerCopyrightRow {
    flex: 0 0 auto !important;
    width: 100% !important;
    text-align: center;
  }
  .footerCopyrightRow {
    text-align: center;
    order: 3;
    margin-top: 30px !important;
    margin-bottom: 60px;
  }
  .footerPrivacyPoliciesRow {
    text-align: center;
    order: 1;
    width: 50% !important;
    margin-top: 40px !important;
    margin-bottom: 0px;
  }
  .footerTermsAndConditionsRow {
    text-align: center;
    order: 2;
    width: 50% !important;
    margin-top: 40px !important;
    margin-bottom: 0px;
  }
}

/* -------------------------------------------------------------------------- */
/*                             Subscribe && slider                            */
/* -------------------------------------------------------------------------- */
.subscribeSection {
  padding: 80px 0;
}

.sliderWrapper {
  width: 100%;
  overflow: auto;
  position: absolute;
  bottom: 20px;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.sliderWrapper::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.sliderWrapper {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.sliderImg {
  height: 300px;
  @include media-breakpoint-up(md) {
    height: 500px;
  }
  @include media-breakpoint-up(lg) {
    height: 650px;
  }
}

.subscribeSliderInnerWrapper {
  background: #f0f2f8;
  border-radius: 40px;
  padding: 70px 28px 100px 28px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  display: flex;
  flex-direction: column;
  align-items: center;

  min-height: 850px;
  @include media-breakpoint-up(xs) {
    min-height: 830px;
  }
  @include media-breakpoint-up(sm) {
    min-height: 700px;
  }
  @include media-breakpoint-up(md) {
    min-height: 1000px;
  }

  @include media-breakpoint-up(lg) {
    min-height: 1150px;
  }

  form {
    width: 100%;
  }
  .subscribeLogoImg {
    margin-bottom: 36px;
  }

  .heroTitleM {
    max-width: 772px;
    text-align: center;
    margin-bottom: 40px;
  }

  .heroInputWrapper {
    max-width: 572px;
  }
}
.subscribeSliderWrapper {
  position: relative;
}

/* -------------------------------------------------------------------------- */
/*                                    SWAL                                    */
/* -------------------------------------------------------------------------- */
.swal2-title {
  max-width: 300px !important;
  font-family: FS Elliot Pro !important;
  font-style: normal !important;
  font-weight: 800 !important;
  font-size: 20px !important;
  line-height: 26px !important;
  text-align: center !important;
  color: #22264c !important;
  margin: auto !important;
}
.swal2-styled.swal2-confirm {
  border: 0 !important;
  border-radius: 0.25em !important;
  background: initial !important;
  background-color: transparent !important;
  color: #e55a5c !important;
  font-size: 1em !important;
  font-family: Lato !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 14px !important;
  line-height: 17px !important;
  text-align: center !important;
  box-shadow: none !important;
}

.about-us-heroes-section {
  .heroTitleM {
    margin-top: 34px;
  }
}

.cookieWrapper {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 24px 24px;
  background: #22264c;
  box-shadow: 0px 6px 20px rgba(34, 38, 76, 0.16);
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 9999999;
  width: calc(100% - 40px);
  max-width: 653px;

  p {
    text-align: center;
    font-family: Lato;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 150%;
    color: #ffffff;
    margin-bottom: 5px;
  }
  button {
    margin-left: 40px;
    width: auto;
    padding: 6px 20px !important;
    font-weight: 500;

    &:hover {
      background-color: #e76e70;
    }
  }
}

.form-check-input {
  width: 1rem;
  height: 1rem;
  margin-right: 10px;

  &:checked {
    background-color: #22264c !important;
  }
}
